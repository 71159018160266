import { IS_DEBUG } from '@common/constants/is-debug.constant';
import React from 'react';
import { Helmet } from 'react-helmet';

export const ConvertSnippet = (): JSX.Element => {
    return !IS_DEBUG ? (
        <Helmet>
            <script type="text/javascript" src="//cdn-4.convertexperiments.com/v1/js/10045648-10045478.js"></script>
        </Helmet>
    ) : (
        <></>
    );
};
