import { getCurrentLocaleTranslationPrefix, getTranslationPrefix } from '@common/utils/get-translation-prefix.util';
import i18n, { TOptions } from 'i18next';
import React, { ReactNode } from 'react';
import { Trans } from 'react-i18next';

// interface IReturn {
//     t: (i18nKey: string, description?: TTranslationCategory, options?: any) => any;
// }

type TTranslationCategory = string;
// export const useT = (): IReturn => {
//     const { t } = useTranslation();
//     return {
//         t: (translation, _description, options): string => {
//             const localePrefix = i18n.exists(translation) ? getCurrentLocaleTranslationPrefix() : '';
//             return `${getTranslationPrefix()}${localePrefix}${t(translation, options)}`;
//         },
//     };
// };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function t(i18nKey: string, _description?: TTranslationCategory, options?: string | TOptions | any): string {
    const localePrefix = i18n.exists(i18nKey) ? getCurrentLocaleTranslationPrefix() : '';
    return `${getTranslationPrefix()}${localePrefix}${i18n.t(i18nKey, options) || i18nKey}`;
}

interface IProps {
    i18nKey?: string;
    children: JSX.Element | JSX.Element[] | ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function T({ children, i18nKey, ...rest }: IProps & any): JSX.Element {
    const localePrefix = i18nKey && i18n.exists(i18nKey) ? getCurrentLocaleTranslationPrefix() : '';
    return (
        <>
            {getTranslationPrefix()}
            {localePrefix}
            <Trans i18nKey={i18nKey} {...rest}>
                {children}
            </Trans>
        </>
    );
}
