import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { CONST } from '@common/constants/const.constant';
import { EAuthentication } from '@common/enums/authentication.enum';
import { sessionStorageService } from '@common/services/session-storage.service';
import { Amplify, Auth } from 'aws-amplify';
import { useCallback } from 'react';

export const AmplifyEx = Amplify;
interface IReturn {
    loginWithFacebook: (info: EAuthentication) => void;
    loginWithGoogle: (info: EAuthentication) => void;
    signOut: () => void;
    signOutRegistration: () => void;
    currentAuthenticatedUser: () => Promise<{
        pool: { clientId: string };
        signInUserSession: {
            idToken: { payload: { email: string }; jwtToken: string };
            refreshToken: { token: string };
            accessToken: { jwtToken: string; payload: { exp: number; iat: number } };
        };
    }>;
}
export const useCognito = (): IReturn => {
    return {
        loginWithFacebook: useCallback((info) => {
            sessionStorageService.save(CONST.sessionStorageKey_SSO, info);
            Auth.federatedSignIn({
                provider: CognitoHostedUIIdentityProvider.Facebook,
            });
        }, []),

        loginWithGoogle: useCallback((info) => {
            sessionStorageService.save(CONST.sessionStorageKey_SSO, info);
            Auth.federatedSignIn({
                provider: CognitoHostedUIIdentityProvider.Google,
            });
        }, []),
        signOut: useCallback(() => {
            sessionStorageService.save(CONST.sessionStorageKey_SSO, EAuthentication.Logout);
            Auth.signOut();
        }, []),
        signOutRegistration: useCallback(() => {
            sessionStorageService.save(CONST.sessionStorageKey_SSO, EAuthentication.RegistrationSignOut);
            Auth.signOut();
        }, []),

        currentAuthenticatedUser: useCallback(() => {
            return Auth.currentAuthenticatedUser();
        }, []),
    };
};
