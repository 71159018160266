export const toBoolean = (val: unknown): boolean => {
    if (!val) {
        return false;
    }
    if (typeof val === 'string') {
        return val.toString().toLowerCase() === 'true';
    }
    if (typeof val === 'number') {
        return val === 1;
    }
    return !!val;
};
