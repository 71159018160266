import { CONST } from '@common/constants/const.constant';
import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
            staleTime: CONST.REACT_QUERY_STALE_TIME,
            refetchOnMount: false,
            keepPreviousData: true,
        },
    },
});
