import { IRouteConfig } from '@common/interfaces/route.interface';
import { lazy } from '@common/utils/lazy.util';
import { LINKS } from '@src/shared/links.constant';

export const GET_ACCOUNT_SITE_ROUTES = (): IRouteConfig => {
    return {
        routes: [
            {
                path: LINKS.other,
                component: lazy(() =>
                    import(/* webpackChunkName: "OtherRoutes" */ '@src/features/other/other-routes.component').then(
                        (module) => ({
                            default: module.OtherRoutes,
                        }),
                    ),
                ),
                isPrivate: false,
                hasAccess: (): boolean => true,
            },
            {
                path: LINKS.auth,
                component: lazy(() =>
                    import(/* webpackChunkName: "AuthRoutes" */ '@src/features/auth/auth-routes.component').then(
                        (module) => ({
                            default: module.AuthRoutes,
                        }),
                    ),
                ),
                isPrivate: false,
                hasAccess: (): boolean => true,
            },

            {
                path: LINKS.account,
                component: lazy(() =>
                    import(/* webpackChunkName: "AccountRoutes" */ '@src/features/account/account-routes.component').then(
                        (module) => ({
                            default: module.AccountRoutes,
                        }),
                    ),
                ),
                isPrivate: true,
                hasAccess: (): boolean => true,
            },
            {
                path: LINKS.register,
                component: lazy(() =>
                    import(/* webpackChunkName: "RegisterRoutes" */ '@src/features/register/register.container').then(
                        (module) => ({
                            default: module.RegisterContainer,
                        }),
                    ),
                ),
                isPrivate: false,
                hasAccess: (): boolean => true,
            },

            {
                path: LINKS.subscribe,
                component: lazy(() =>
                    import(
                        /* webpackChunkName: "SubscribeContainer" */ '@src/features/subscribe-or-unsubscribe/containers/subscribe-or-unsubscribe.container'
                    ).then((module) => ({
                        default: module.SubscribeOrUnsubscribeContainer,
                    })),
                ),
                isPrivate: false,
                hasAccess: (): boolean => true,
            },
            {
                path: LINKS.unsubscribe,
                component: lazy(() =>
                    import(
                        /* webpackChunkName: "SubscribeContainer" */ '@src/features/subscribe-or-unsubscribe/containers/subscribe-or-unsubscribe.container'
                    ).then((module) => ({
                        default: module.SubscribeOrUnsubscribeContainer,
                    })),
                ),
                isPrivate: false,
                hasAccess: (): boolean => true,
            },

            {
                path: LINKS.index,
                component: lazy(() =>
                    import(/* webpackChunkName: "HomeRoutes" */ '@src/features/account/home-routes.component').then(
                        (module) => ({
                            default: module.HomeRoutes,
                        }),
                    ),
                ),

                exact: true,
                isPrivate: false,
                hasAccess: (): boolean => true,
            },
        ],

        redirects: [
            {
                to: LINKS.other.notFound,
            },
        ],
    };
};
