type ICallback<T> = (data?: T) => void;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class EventEmitter<T = any> {
    _items: ICallback<T>[] = [];
    subscribe(callback: ICallback<T>): void {
        this._items.push(callback);
    }
    unsubscribe(callback: ICallback<T>): void {
        this._items = this._items.filter((f) => f !== callback);
    }
    emit(data?: T): void {
        this._items.forEach((f) => f(data));
    }
}
export const resizeEvent = new EventEmitter();
