function load<T>(key: string, initial?: T): T | undefined {
    const json = sessionStorage.getItem(key);
    if (json) {
        try {
            return JSON.parse(json) as T;
        } catch {
            return initial;
        }
    }
    return initial;
}

function save<T>(key: string, data: T | undefined): void {
    if (data) {
        sessionStorage.setItem(key, JSON.stringify(data));
    } else {
        sessionStorage.removeItem(key);
    }
}

function remove(key: string): void {
    sessionStorage.removeItem(key);
}

export const sessionStorageService = {
    load,
    save,
    remove,
};
