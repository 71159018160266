import { IAuthInfo } from '@common/interfaces/auth-info.interface';
import { IAuthService } from '@common/interfaces/auth-service.interface';
import { EventEmitter } from '@common/utils/event-emitter.util';
import { QueryClient } from 'react-query';
import { authCookieServiceInternal } from './auth-cookie-internal.service';
import { authLocalStorageServiceInternal } from './auth-local-storage-internal.service';

export * from '@common/interfaces/auth-info.interface';

let _inst: IAuthService | undefined = undefined;
export const authService = {
    init: ({ type, qc, cookieDomain }: { type: 'cookie' | 'localStorage'; qc: QueryClient; cookieDomain: string }): void => {
        if (type === 'cookie') {
            _inst = authCookieServiceInternal;
        } else {
            _inst = authLocalStorageServiceInternal;
        }
        _inst.init(qc, cookieDomain);
    },
    signOutEvent: new EventEmitter(),
    removeToken: (): void => {
        _inst?.removeToken();
    },
    saveInfo: (data: IAuthInfo): void => {
        _inst?.saveInfo(data);
    },
    getInfo: (): IAuthInfo | undefined => {
        return _inst?.getInfo();
    },

    signOut: (): void => {
        _inst?.signOut();
    },
};
