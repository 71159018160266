import { useCallback, useRef } from 'react';
import { ModalProvider, useModal } from 'react-modal-hook';

type IReturn<TProps> = [(props: TProps) => void, () => void];

export const ModalProviderEx = ModalProvider;

export function useModalEx<TProps>(
    callback: (props: TProps, hideModal: () => void) => JSX.Element,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _deps?: any[],
): IReturn<TProps> {
    const propsRef = useRef<TProps>();

    const [showModalInternal, hideModalInternal] = useModal(() => {
        return callback(propsRef.current as TProps, hideModalInternal);
    }, [callback]);
    return [
        useCallback(
            (props: TProps) => {
                propsRef.current = props;
                showModalInternal();
            },
            [showModalInternal],
        ),
        useCallback(() => {
            hideModalInternal();
        }, [hideModalInternal]),
    ];
}
