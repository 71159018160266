export enum EAuthentication {
    RegistrationSignIn = 'registrationSignIn',
    RegistrationSignOut = 'registrationSignOut',
    Login = 'login',
    AdminLogin = 'adminLogin',
    Logout = 'logout',
}

export enum ECognitoOperation {
    loginWithFacebook = 'loginWithFacebook',
    loginWithGoogle = 'loginWithGoogle',
    logout = 'logout',
}
