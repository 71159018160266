export interface IBEApiConfig {
    apiUrl: string;
    swaggerUrl: string;
    xToken: string;
    fileUrl: string;
}
interface IBEApiConfigs {
    packHouseService: IBEApiConfig;
    registrationService: IBEApiConfig;
    itemService: IBEApiConfig;
    utilService: IBEApiConfig;
}
export const BE_API_URLS: IBEApiConfigs = {
    packHouseService: {
        apiUrl: process.env.REACT_APP_pack_house_api || '',
        swaggerUrl: process.env.REACT_APP_pack_house_swagger || '',
        xToken: process.env.REACT_APP_pack_house_x_token || '',
        fileUrl: '_pack-house/swagger.yml',
    },
    registrationService: {
        apiUrl: process.env.REACT_APP_registration_api || '',
        swaggerUrl: process.env.REACT_APP_registration_swagger || '',
        xToken: process.env.REACT_APP_registration_x_token || '',
        fileUrl: '_registration/swagger.yml',
    },

    itemService: {
        apiUrl: process.env.REACT_APP_item_api || '',
        swaggerUrl: process.env.REACT_APP_item_swagger || '',
        xToken: process.env.REACT_APP_item_x_token || '',
        fileUrl: '_item/swagger.yml',
    },

    utilService: {
        apiUrl: process.env.REACT_APP_util_api || '',
        swaggerUrl: process.env.REACT_APP_util_swagger || '',
        xToken: process.env.REACT_APP_util_x_token || '',
        fileUrl: '_util/swagger.yml',
    },
};
