import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import 'antd/dist/antd.less';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import App from './App';
import './i18n';
import { interceptorService } from './shared/interceptor.service';

interceptorService.setupRequest();
interceptorService.setupResponse();

if (process.env.REACT_APP_SENTRY_URL) {
    const SENTRY_ENV_MAP = {
        dev: 'development',
        stage: 'staging',
        prod: 'production',
    };
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL,
        integrations: [new BrowserTracing()],
        maxValueLength: 100000,
        tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE + ''),
        environment: SENTRY_ENV_MAP[process.env.REACT_APP_ENV_FILE + ''],
    });
}

// Initialize Google Tag Manager
if (process.env.REACT_APP_GTM_CONTAINER_ID) {
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
    };
    TagManager.initialize(tagManagerArgs);
}
ReactDOM.render(<App />, document.getElementById('root'));
