import { useCallback, useEffect, useRef } from 'react';

interface IReturn<T> {
    start: (arg: T) => void;
    clear: () => void;
}

export function useTimeout<T>(callback: (arg: T) => void, interval?: number): IReturn<T> {
    const i = useRef<number>(0);

    useEffect(() => {
        return (): void => {
            clearTimeout(i.current);
        };
    }, [callback, interval]);

    return {
        start: useCallback(
            (arg: T): void => {
                clearTimeout(i.current);
                i.current = setTimeout(() => callback(arg), interval) as unknown as number;
            },
            [callback, interval],
        ),

        clear: useCallback((): void => {
            clearTimeout(i.current);
        }, []),
    };
}
