import { RenderRoutes } from '@common/general-ui/render-routes/render-routes.component';
import { useCognito } from '@common/hooks/use-cognito.hook';
import { useTimeout } from '@common/hooks/use-timeout.hook';
import { authService } from '@common/services/auth.service';
import { globalSettingsService } from '@common/services/global-settings.service';
import { EFeature } from '@src/api/registration-generated-api';
import { LINKS } from '@src/shared/links.constant';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { GET_ACCOUNT_SITE_ROUTES } from './account-site.routes';

export const RenderRootRoutes = (): JSX.Element => {
    const history = useHistory();
    const { signOut } = useCognito();
    const { start } = useTimeout(
        useCallback(() => {
            authService.signOut();
            history.push(LINKS.auth.login.toString());
        }, [history]),
        10000,
    );
    const handleSignOut = useCallback((): void => {
        if (authService.getInfo()?.is_sso) {
            signOut();
            start(undefined);
        } else {
            authService.signOut();
            history.push(LINKS.auth.login.toString());
        }
    }, [history, signOut, start]);

    useEffect(() => {
        authService.signOutEvent.subscribe(handleSignOut);
        return (): void => authService.signOutEvent.unsubscribe(handleSignOut);
    }, [handleSignOut]);

    useEffect(() => {
        globalSettingsService.saveInfo({ features: [EFeature.example] });
    }, []);

    const config = useMemo(() => GET_ACCOUNT_SITE_ROUTES(), []);

    return (
        <RenderRoutes
            loginUrl={LINKS.auth.login}
            dontHavePermissionUrl={LINKS.other.dontHavePermission}
            config={config}
            getToken={(): string | undefined => authService.getInfo()?.id_token}
        />
    );
};
