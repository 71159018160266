import { CONST } from '@common/constants/const.constant';
import { IAuthInfo } from '@common/interfaces/auth-info.interface';
import { IAuthService } from '@common/interfaces/auth-service.interface';
import { sessionStorageService } from '@common/services/session-storage.service';
import { cookieUtils } from '@common/utils/cookie.util';
import { parseJSON } from '@common/utils/parse-json.util';
import { toBoolean } from '@common/utils/to-boolean.util';
import { QueryClient } from 'react-query';
import { consoleService } from './console.service';

// Cookie size limit 4KB
const COOKIE_AUTH_ACCESS_TOKEN = 'auth_access_token';
const COOKIE_AUTH_ID_TOKEN = 'auth_id_token';
const COOKIE_AUTH_REFRESH_TOKEN = 'auth_refresh_token';
const COOKIE_AUTH_EXPIRES_IN = 'auth_expires_in';
const COOKIE_AUTH_ROLE = 'auth_role';
const COOKIE_AUTH_PERMISSIONS = 'auth_permissions';
const COOKIE_AUTH_CUSTOMER_ID = 'auth_customer_id';
const COOKIE_AUTH_APP_CLIENT_ID = 'auth_app_client_id';
const COOKIE_AUTH_IS_SSO = 'auth_is_sso';

const COOKIE_VALID_DAYS_COUNT = 180;

let _info: IAuthInfo | undefined = undefined;
let _queryClient: QueryClient | undefined = undefined;
let _domain = '';
export const authCookieServiceInternal: IAuthService = {
    init: (qc: QueryClient, cookieDomain: string): void => {
        _queryClient = qc;
        _domain = cookieDomain;
    },

    removeToken: (): void => {
        _info = undefined;
        cookieUtils.eraseCookie(COOKIE_AUTH_ACCESS_TOKEN, _domain);
        cookieUtils.eraseCookie(COOKIE_AUTH_ID_TOKEN, _domain);
        cookieUtils.eraseCookie(COOKIE_AUTH_REFRESH_TOKEN, _domain);
        cookieUtils.eraseCookie(COOKIE_AUTH_EXPIRES_IN, _domain);
        cookieUtils.eraseCookie(COOKIE_AUTH_ROLE, _domain);
        cookieUtils.eraseCookie(COOKIE_AUTH_PERMISSIONS, _domain);
        cookieUtils.eraseCookie(COOKIE_AUTH_CUSTOMER_ID, _domain);
        cookieUtils.eraseCookie(COOKIE_AUTH_APP_CLIENT_ID, _domain);
        cookieUtils.eraseCookie(COOKIE_AUTH_IS_SSO, _domain);
    },
    saveInfo: (data: IAuthInfo): void => {
        _info = data;

        cookieUtils.setCookie(COOKIE_AUTH_ACCESS_TOKEN, data.access_token || '', COOKIE_VALID_DAYS_COUNT, _domain);
        cookieUtils.setCookie(COOKIE_AUTH_ID_TOKEN, data.id_token || '', COOKIE_VALID_DAYS_COUNT, _domain);
        cookieUtils.setCookie(COOKIE_AUTH_REFRESH_TOKEN, data.refresh_token || '', COOKIE_VALID_DAYS_COUNT, _domain);
        cookieUtils.setCookie(COOKIE_AUTH_EXPIRES_IN, data.expires_in?.toString() || '', COOKIE_VALID_DAYS_COUNT, _domain);
        cookieUtils.setCookie(COOKIE_AUTH_ROLE, data.role || '', COOKIE_VALID_DAYS_COUNT, _domain);
        cookieUtils.setCookie(COOKIE_AUTH_PERMISSIONS, JSON.stringify(data.permissions), COOKIE_VALID_DAYS_COUNT, _domain);
        cookieUtils.setCookie(COOKIE_AUTH_CUSTOMER_ID, data.customer_id || '', COOKIE_VALID_DAYS_COUNT, _domain);
        cookieUtils.setCookie(COOKIE_AUTH_APP_CLIENT_ID, data.app_client_id || '', COOKIE_VALID_DAYS_COUNT, _domain);
        cookieUtils.setCookie(COOKIE_AUTH_IS_SSO, data.is_sso?.toString() || '', COOKIE_VALID_DAYS_COUNT, _domain);
    },
    getInfo: (): IAuthInfo | undefined => {
        if (!_info && cookieUtils.getCookie(COOKIE_AUTH_ACCESS_TOKEN)) {
            _info = {
                access_token: cookieUtils.getCookie(COOKIE_AUTH_ACCESS_TOKEN),
                id_token: cookieUtils.getCookie(COOKIE_AUTH_ID_TOKEN),
                refresh_token: cookieUtils.getCookie(COOKIE_AUTH_REFRESH_TOKEN),
                expires_in: parseInt(cookieUtils.getCookie(COOKIE_AUTH_EXPIRES_IN) || ''),
                role: cookieUtils.getCookie(COOKIE_AUTH_ROLE),
                permissions: parseJSON(cookieUtils.getCookie(COOKIE_AUTH_PERMISSIONS) || '[]'),
                customer_id: cookieUtils.getCookie(COOKIE_AUTH_CUSTOMER_ID),
                app_client_id: cookieUtils.getCookie(COOKIE_AUTH_APP_CLIENT_ID),
                is_sso: toBoolean(cookieUtils.getCookie(COOKIE_AUTH_IS_SSO)),
            };
        }
        return _info;
    },

    signOut: (): void => {
        consoleService.log('auth - before remove', authCookieServiceInternal.getInfo());
        if (authCookieServiceInternal.getInfo()) {
            localStorage.setItem('auth-before-remove', JSON.stringify(authCookieServiceInternal.getInfo(), null, 2));
        }
        authCookieServiceInternal.removeToken();

        // eraseCookie('cognito');
        // eraseCookie('XSRF-TOKEN');

        for (const key in localStorage) {
            if (key.toLowerCase().includes('cognito')) {
                localStorage.removeItem(key);
            }
        }

        sessionStorageService.remove(CONST.sessionStorageKey_SSO);
        _queryClient?.removeQueries();
    },
};
