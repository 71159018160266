export interface IGlobalSettingsInfo {
    features: string[] | undefined;
}
const KEY = 'global-settings';

let _info: IGlobalSettingsInfo | undefined = undefined;
export const globalSettingsService = {
    removeInfo: (): void => {
        _info = undefined;
        localStorage.removeItem(KEY);
    },
    saveInfo: (data: IGlobalSettingsInfo): void => {
        _info = data;
        localStorage.setItem(KEY, JSON.stringify(data, null, 2));
    },
    getInfo: (): IGlobalSettingsInfo | undefined => {
        if (!_info) {
            const json = localStorage.getItem(KEY);
            if (json) {
                _info = JSON.parse(json);
            }
        }
        return _info;
    },
};
