// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const removeSecureDataFromLog = (data: any): any => {
    if (data) {
        if (data.password) {
            data.password = 'removed_from_log';
        }
        if (data.stripe_setup_intent_id) {
            data.stripe_setup_intent_id = 'removed_from_log';
        }
        if (data.stripe_permanent_token_id) {
            data.stripe_permanent_token_id = 'removed_from_log';
        }
        if (typeof data === 'string') {
            let res = data.replace(/"password":".*?"/gm, '"password":"removed_from_log"');
            res = res.replace(/"stripe_setup_intent_id":".*?"/gm, '"stripe_setup_intent_id":"removed_from_log"');
            res = res.replace(/"stripe_permanent_token_id":".*?"/gm, '"stripe_permanent_token_id":"removed_from_log"');
            return res;
        }
        return data;
    }
    return data;
};
