import { CloseOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import React, { ReactNode } from 'react';

interface INotifyProps {
    type: 'success' | 'error' | 'info';
    title: ReactNode;
    description?: ReactNode;
    onShown?: (() => void) | undefined;
}

const duration = 10;
let visibleNotifies: string[] = [];
export const notify = ({ type, title, description, onShown }: INotifyProps): void => {
    const id = `${title}-${description}`;
    if (!visibleNotifies.includes(id)) {
        visibleNotifies.push(id);

        notification[type]({
            message: <div style={styles.title}>{title}</div>,
            description: <div style={styles.descriptionStyle}>{description}</div>,
            duration,
            onClose: () => {
                visibleNotifies = visibleNotifies.filter((f) => f !== id);
            },
            closeIcon: (
                <div>
                    <CloseOutlined style={styles.icon} />
                </div>
            ),
            style: styles.root,
        });
        onShown && onShown();
    }
};

const styles = {
    root: {
        borderRadius: '6px',
        border: '1px solid #EEEEEE',
        padding: '16px',
    },
    title: {
        fontSize: '16px',
    },
    descriptionStyle: {
        fontSize: '14px',
        fontWeight: 100,
        marginRight: '24px',
    },
    icon: {
        color: 'black',
    },
};
