import { consoleService } from '@common/services/console.service';
import { isCypress } from '@common/utils/is-cypress.util';
// export const getNow = (): Date => new Date('2022-9-20 13:00');
export const getNow = (): Date => {
    if (isCypress()) {
        const cypressDate = (window as unknown as { CYPRESS_NOW: Date }).CYPRESS_NOW || new Date();
        const copy = new Date();
        copy.setTime(cypressDate.getTime());
        return copy;
    }
    return new Date();
};

export const setCypressNow = (wnd: Window, date: Date): void => {
    if (isCypress()) {
        consoleService.log('CYPRESS SET NOW', date);
        (wnd as unknown as { CYPRESS_NOW: Date }).CYPRESS_NOW = date;
    }
};
