import { IBEError } from '@common/interfaces/be-error.interface';
import { ReactNode } from 'react';

export interface ISuccessItem {
    isSuccess: boolean;
    title?: ReactNode;
    description?: ReactNode;
    onShown: (() => void) | undefined;
}

export interface IErrorItem {
    beError: IBEError<unknown> | (IBEError<unknown> | undefined | null | boolean);
    onShown: (() => void) | undefined;
    title?: ReactNode;
    description?: ReactNode;
}

let _successHandler: (error: ISuccessItem) => void;
let _errorHandler: (error: IErrorItem) => void;
export const customNotify = {
    init: ({
        successHandler,
        errorHandler,
    }: {
        successHandler: (success: ISuccessItem) => void;
        errorHandler: (error: IErrorItem) => void;
    }): void => {
        _successHandler = successHandler;
        _errorHandler = errorHandler;
    },

    success: (items: ISuccessItem[] | undefined): void => {
        if (items && items.length) {
            items
                .filter((item) => item && item.isSuccess)
                .forEach((item) => {
                    _successHandler(item);
                });
        }
    },

    error: (items: IErrorItem[] | undefined): void => {
        if (items && items.length) {
            items
                .filter((e) => e && e.beError)
                .forEach((e) => {
                    _errorHandler(e);
                });
        }
    },
};
