import { IBEError } from '@common/interfaces/be-error.interface';
import { IHttpRequestPart } from '@common/interfaces/http-request-part.interface';
import { authService } from '@common/services/auth.service';
import { consoleService } from '@common/services/console.service';
import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse, requestService } from '@common/services/request.service';
import { isCypress } from '@common/utils/is-cypress.util';
import { removeSecureDataFromLog } from '@common/utils/remove-secure-data-from-log.util';
import * as Sentry from '@sentry/react';
import { TErrorCodes } from '@src/api/error-codes.type';
import { IRefreshResponse, registrationApiRequest } from '@src/api/registration-generated-api';
import { BE_API_URLS, IBEApiConfig } from '@src/shared/be-api-urls.constant';

const findBeConfig = (config: AxiosRequestConfig): IBEApiConfig | undefined => {
    if (config.url?.includes('api/')) {
        const key = Object.keys(BE_API_URLS).find((key) => {
            const beConfig: IBEApiConfig = BE_API_URLS[key];
            return beConfig.apiUrl && config.url?.includes(beConfig.apiUrl);
        });
        if (key) {
            const beConfig = BE_API_URLS[key] as IBEApiConfig;
            return beConfig;
        }
    }
    return undefined;
};
export const interceptorService = {
    setupRequest: (): void => {
        axios.interceptors.request.use(
            function (config) {
                const beConfig = findBeConfig(config);

                if (beConfig) {
                    const info = authService.getInfo();
                    config.headers['x-api-key'] = beConfig.xToken;
                    if (info?.id_token) {
                        config.headers['Authorization'] = `Bearer ${info.id_token}`;
                        config.headers['App-Client-Id'] = info.app_client_id;
                    }
                }

                return config;
            },
            function (error) {
                return Promise.reject(error);
            },
        );
    },
    setupResponse: (): void => {
        axios.interceptors.response.use(
            function (response) {
                const { config } = response;
                if (isCypress()) {
                    const beConfig = findBeConfig(config);
                    if (beConfig) {
                        const pathName = config.url?.replace(beConfig.apiUrl, '');

                        const mock = getInterceptedApis().find(
                            (f) => f.method.toLowerCase() === config.method?.toLowerCase() && f.url === pathName,
                        );
                        if (mock) {
                            response.data = mock.response;
                            consoleService.log(
                                `[SUCCESS: CYPRESS REQUEST INTERCEPTED] -> '${config.method?.toUpperCase()}' - ${pathName}`,
                                mock.response,
                            );
                        }
                    }
                }
                return response;
            },
            function (ex) {
                if (ex?.message === 'Network Error') {
                    consoleService.error('client error', ex);
                    return Promise.reject({
                        error: 'Frontend_NetworkError',
                        status: 'failed',
                        valid: false,
                    } as IBEError<TErrorCodes>);
                }
                const { response } = ex;
                const { config } = response;
                if (config.url.includes('/api/') && config.url.includes('/refresh') && response.status > 200) {
                    authService.signOutEvent.emit();
                    return Promise.reject({
                        response,
                    });
                } else if (
                    response.status === 401 &&
                    config.url.includes('/api/') &&
                    !config.url.includes('/login') &&
                    !config.url.includes('/sign_out')
                ) {
                    return requestRefreshHeadersAndMakeSameRequestAgain(response);
                } else if (ex?.config.url?.includes('api/')) {
                    if (!config.url.includes('/login')) {
                        if (process.env.REACT_APP_SENTRY_URL) {
                            if (config) {
                                Sentry.captureMessage(
                                    JSON.stringify({
                                        customError: ex.message,
                                        body: removeSecureDataFromLog(config.data),
                                        response: response.data,
                                    }),
                                );
                            } else {
                                Sentry.captureException(ex);
                            }
                        }
                    }

                    const key = Object.keys(BE_API_URLS).find((key) => {
                        const beConfig: IBEApiConfig = BE_API_URLS[key];
                        return ex.config.url?.includes(beConfig.apiUrl);
                    });
                    const beConfig = BE_API_URLS[key as string] as IBEApiConfig;
                    if (beConfig) {
                        // consoleService.error('interceptor error', beConfig, e.response.data);
                        return Promise.reject(ex.response.data as IBEError<TErrorCodes>);
                    }
                    return Promise.reject(ex?.response?.data);
                }
                return Promise.reject(ex);
            },
        );
    },
};
export const CYPRESS_SESSION_STORAGE_KEY = 'cypress-intercepts';
const getInterceptedApis = (): IHttpRequestPart[] => {
    const mock = sessionStorage.getItem(CYPRESS_SESSION_STORAGE_KEY);
    return mock ? JSON.parse(mock) : [];
};

const requestRefreshHeadersAndMakeSameRequestAgain = (response: AxiosResponse): AxiosPromise => {
    const { config } = response;
    // replace with admin service
    const refreshEndpoint = registrationApiRequest.registrationV1RefreshPost;

    return refreshEndpoint(
        {
            id_token: authService.getInfo()?.id_token || '',
            refresh_token: authService.getInfo()?.refresh_token || '',
        },
        {},
    )
        .then((data) => {
            // TODO recursion! Improve this logic for more secure.
            authService.saveInfo({
                access_token: data.data.access_token || '',
                customer_id: (data.data as IRefreshResponse).chargebee_customer_id,
                expires_in: data.data.expires_in,
                id_token: data.data.id_token,
                app_client_id: data.data.app_client_id,
                role: authService.getInfo()?.role,
                refresh_token: authService.getInfo()?.refresh_token,
                is_sso: authService.getInfo()?.is_sso,
                permissions: authService.getInfo()?.permissions || [],
            });
        })
        .catch(() => {
            authService.signOutEvent.emit();
            return Promise.reject({
                response,
            });
        })
        .then(() => {
            return requestService.request(
                config.method as string,
                config.url as string,
                config.data,
            ) as unknown as AxiosPromise;
        });
};
