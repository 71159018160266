// For simple find code that not translated - open browser and set for example '#' symbol for quickly find not translated strings
// for example look https://monosnap.com/file/prZVuKAMvwLubZbCg4z2wr5UpqkiAs
export const getTranslationPrefix = (): string => {
    const result = localStorage.getItem('TRANSLATION_PREFIX') || '';
    if (!result) {
        localStorage.setItem('TRANSLATION_PREFIX', '');
    }
    return result;
};

// use this symbols for looking missed translation strings for example use '$' symbol
export const getCurrentLocaleTranslationPrefix = (): string => {
    const result = localStorage.getItem('CURRENT_LOCATE_TRANSLATION_PREFIX') || '';
    if (!result) {
        localStorage.setItem('CURRENT_LOCATE_TRANSLATION_PREFIX', '');
    }
    return result;
};
