import { CONST } from '@common/constants/const.constant';
import { IAuthInfo } from '@common/interfaces/auth-info.interface';
import { IAuthService } from '@common/interfaces/auth-service.interface';
import { sessionStorageService } from '@common/services/session-storage.service';
import { QueryClient } from 'react-query';
import { consoleService } from './console.service';

const KEY = 'auth';

// for admin panel more better use localStorage then cookie (not possible work in some time in DEV moe with customer site - because used same domain on localhost)
let _info: IAuthInfo | undefined = undefined;
let _queryClient: QueryClient | undefined = undefined;
export const authLocalStorageServiceInternal: IAuthService = {
    init: (qc: QueryClient, _: string): void => {
        _queryClient = qc;
    },
    removeToken: (): void => {
        _info = undefined;
        localStorage.removeItem(KEY);
    },
    saveInfo: (data: IAuthInfo): void => {
        _info = data;
        localStorage.setItem(KEY, JSON.stringify(data, null, 2));
    },
    getInfo: (): IAuthInfo | undefined => {
        if (!_info) {
            const json = localStorage.getItem(KEY);
            if (json) {
                _info = JSON.parse(json);
            }
        }
        return _info;
    },

    signOut: (): void => {
        consoleService.log('auth - before remove', authLocalStorageServiceInternal.getInfo());
        if (authLocalStorageServiceInternal.getInfo()) {
            localStorage.setItem('auth-before-remove', JSON.stringify(authLocalStorageServiceInternal.getInfo(), null, 2));
        }
        authLocalStorageServiceInternal.removeToken();

        // eraseCookie('cognito');
        // eraseCookie('XSRF-TOKEN');

        for (const key in localStorage) {
            if (key.toLowerCase().includes('cognito')) {
                localStorage.removeItem(key);
            }
        }

        sessionStorageService.remove(CONST.sessionStorageKey_SSO);
        _queryClient?.removeQueries();
    },
};
