/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

export const awsConfig = {
    aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
    aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
    aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id:
        process.env.REACT_APP_NODE_ENV === 'cypress' || localStorage.getItem('REACT_APP_NODE_ENV') === 'cypress'
            ? process.env.REACT_APP_AWS_CYPRESS_USER_POOLS_WEBCLIENT_ID
            : process.env.REACT_APP_AWS_USER_POOLS_WEBCLIENT_ID,
    oauth: {
        domain: process.env.REACT_APP_AWS_DOMAIN,
        scope: ['email', 'openid', 'phone', 'profile'],
        redirectSignIn: process.env.REACT_APP_AWS_REDIRECT_SIGN_IN,
        redirectSignOut: process.env.REACT_APP_AWS_REDIRECT_SIGN_OUT,
        responseType: 'code',
    },
    federationTarget: 'COGNITO_USER_POOLS',
    aws_cognito_username_attributes: ['EMAIL'],
    aws_cognito_social_providers: ['FACEBOOK', 'GOOGLE'],
    aws_cognito_signup_attributes: ['EMAIL'],
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_mfa_types: [],
    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: ['REQUIRES_LOWERCASE', 'REQUIRES_UPPERCASE', 'REQUIRES_NUMBERS'],
    },
    aws_cognito_verification_mechanisms: [],
};
